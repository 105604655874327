import { Backdrop, CircularProgress } from '@mui/material';

interface Props {
  open: boolean;
  text?: string;
}

export const LoadingOverlay = ({ open, text = 'Loading...' }: Props) => (
  <Backdrop
    invisible
    open={open}
    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <div className="flex justify-center items-center p-8 bg-white color-black border rounded-lg shadow-md">
      <CircularProgress color="inherit" />

      <div className="ml-4">
        {text}
      </div>
    </div>
  </Backdrop>
);
