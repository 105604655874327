import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import {
  Dialog, DialogContent, DialogActions, TextField,
  FormGroup, Checkbox, FormControlLabel, Avatar,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useApi } from 'lib/contexts/ApplicationState';
import { useSnackbar } from 'notistack';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { NetworkIcon } from 'elements/NetworkIcon';
import { NETWORK_IDS, POSTING_ACCOUNT_TYPE } from 'lib/constants';
import { postingAccountToUrl } from 'lib/helpers';
import { PostingAccount } from 'lib/types/postingAccounts';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { User } from 'lib/types/users';

interface Props {
  onClose: () => void;
  onAdd: (accounts: Option[]) => void;
  user: User;
  postingAccounts: PostingAccount[];
}

interface Option extends Partial<PostingAccount> {
  avatarUrl: string;
  name: string;
  networkId?: number | string;
}

interface SocialOptions {
  twitter: Option;
  instagram: Option;
  tiktok: Option;
  existingUser: Option;
  twitch: Option;
  youtube: Option;
  facebook: Option;
  threads: Option;
}

export const AddPostingAccountsModal = ({
  onClose, onAdd, user, postingAccounts,
}: Props) => {
  const API = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState(user.username);
  const [selected, setSelected] = useState<Option[]>([]);
  const [options, setOptions] = useState<SocialOptions>();
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async () => {
    setOptions(undefined);
    setSelected([]);
    const regEx = new RegExp(/^[\w.]+$/i);
    if (!account || !regEx.test(account)) {
      enqueueSnackbar('Please provide a valid username', {
        variant: 'error',
      });
      return;
    }
    try {
      setIsSearching(true);
      const results = await API.lookUpAllNetworksForPostingAccount(account);
      setOptions(results);
    } catch (err) {
      enqueueSnackbar(`Something went wrong: ${err}`, {
        variant: 'error',
      });
    }
    setIsSearching(false);
  };

  const handleOptionChange = (option: Option) => {
    setSelected((prevSelected) => (prevSelected.includes(option)
      ? prevSelected.filter((item) => item !== option)
      : [...prevSelected, option]));
  };

  const socialOption = (option: Option, network: number) => {
    if (isEmpty(option) || !option.name) {
      return null;
    }

    const alreadyConnected = !!postingAccounts.find((pa) => (
      pa.network === network
      && pa.account.toLowerCase() === option?.account?.toLowerCase()
      && pa.type === POSTING_ACCOUNT_TYPE.owner));

    return (
      <FormControlLabel
        value={option}
        label={(
          <div className="flex items-center gap-4 py-2">
            {
              network === 0
                ? <img alt="logo" src="/red-logo.png" width={24} height={24} />
                : <NetworkIcon network={network} showColor />
            }
            <Avatar
              sx={{ width: 48, height: 48 }}
              alt={option.name}
              src={option.avatarUrl && imageAvatarUrl(option.avatarUrl)}
            />
            <a className="" href={postingAccountToUrl(network, option.name)} target="_blank" rel="noreferrer">
              <div className="text-sm">
                <div className="font-semibold">
                  @
                  {option.account}
                </div>
                <div>
                  {option.name}
                </div>
              </div>
            </a>
          </div>
        )}
        control={(
          <Checkbox
            checked={selected.includes(option) || alreadyConnected}
            onChange={() => handleOptionChange(option)}
            disabled={alreadyConnected}
          />
        )}
      />
    );
  };

  const notFound = (result: SocialOptions) => {
    if (!result) return true;

    return isEmpty(result.existingUser) && isEmpty(result.instagram)
      && isEmpty(result.tiktok) && isEmpty(result.twitter) && isEmpty(result.twitch)
      && isEmpty(result.youtube) && isEmpty(result.facebook) && isEmpty(result.threads);
  };

  const getOptions = () => {
    if (!options) {
      return null;
    }
    if (notFound(options)) {
      return (
        <div>
          <b>
            @
            {selected}
          </b>
          {' '}
          wasn&apos;t found on any platforms.
        </div>
      );
    }

    return (
      <>
        {socialOption(options.twitter, NETWORK_IDS.twitter)}
        {socialOption(options.instagram, NETWORK_IDS.instagram)}
        {socialOption(options.tiktok, NETWORK_IDS.tiktok)}
        {socialOption(options.twitch, NETWORK_IDS.twitch)}
        {socialOption(options.youtube, NETWORK_IDS.youtube)}
        {socialOption(options.facebook, NETWORK_IDS.facebook)}
        {socialOption(options.threads, NETWORK_IDS.threads)}

      </>
    );
  };

  const handleAdd = () => {
    const accountsToAdd = [...selected];

    if (!accountsToAdd.length) {
      return;
    }

    onAdd(accountsToAdd);
  };

  return (
    <Dialog
      open
      onClose={onClose}
    >
      <div className="p-4">
        <h1 className="text-lg font-semibold">Find Social Accounts</h1>
        <h2 className="text-sm text-gray-500">
          Find accounts for
          {' '}
          <strong>{user.name}</strong>
        </h2>
        <div className="absolute right-2 top-2">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <TextField
            className="mb-4"
            id="account"
            placeholder="Account handle"
            type="text"
            onChange={(e) => setAccount(e.target.value)}
            value={account}
            variant="outlined"
            size="small"
            fullWidth
          />
          <button type="button" className="btn-primary my-2" onClick={handleSearch} disabled={isSearching}>Search</button>
          {isSearching && <LoadingOverlay open text="Looking up accounts..." />}
          {options && !isEmpty(options) && (
            <FormGroup>
              {getOptions()}
            </FormGroup>
          )}
        </DialogContent>
        <DialogActions>
          <button
            disabled={!account || isEmpty(selected)}
            className="btn-primary"
            onClick={handleAdd}
            type="submit"
          >
            Add
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
