import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useModal } from 'mui-modal-provider';
import { trackClickContactEvent } from 'lib/hooks/useEventTracking';
import { User } from 'lib/types/users';
import { useSignUpModal } from 'components/SignUpModal';
import { ContactModal } from 'components/ContactModal';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  user: User;
}

export const MailButton = ({ user, ...props }: Props) => {
  const { showSignUpModal } = useSignUpModal();
  const { showModal } = useModal();

  const API = useApi();
  const currentUser = useCurrentUser();
  const tryToContact = async () => {
    if (currentUser) {
      await trackClickContactEvent(API, user.id);
      const modal = showModal(ContactModal, {
        profileUser: user,
        onCancel: () => {
          modal.destroy();
        },
      });
    } else {
      showSignUpModal('Please sign up or sign in to contact a creator');
    }
  };

  return (
    <button
      type="button"
      title="contact via email"
      onClick={tryToContact}
    >
      <MailOutlineIcon {...props} />
    </button>
  );
};
