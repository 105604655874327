import React, { SVGProps } from 'react';

export const ProCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 45.3 45.2"
    style={{
      width: '100%',
      height: '100%',
    }}
    {...props}
  >
    <style>
      {
        '\n\t.st2{fill:#FFFFFF;}\n\t.st3{fill:url(#SVGID_1_);stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}\n'
      }
    </style>
    <g>
      <rect x={14.2} y={19.4} className="st2" width={18.4} height={9.7} />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={11.4332}
        y1={21.6544}
        x2={35.4856}
        y2={21.6544}
      >
        <stop
          offset={0.3328}
          style={{
            stopColor: '#DAA76E',
          }}
        />
        <stop
          offset={0.7528}
          style={{
            stopColor: '#EDC679',
          }}
        />
      </linearGradient>
      <path
        className="st3"
        d="M32.9,19.1c-0.4-0.6-1-0.9-1.7-0.9h-5.9v0c1.2-3.5,1.2-7.2,0.1-10.6v0l-1.2-2.8c0,0,0,0,0,0 c-0.1-0.2-0.2-0.4-0.3-0.6l-3.5,1.5l-6.6,2.9l0.5,1.1l0.5,1.1l0.6,1.2l6.5-2.9c0.9,3,0.6,6.2-0.6,9.1c0,0,0,0,0,0h-5.7 c-0.8,0-1.6,0.5-1.9,1.2c-0.9,2.1-1.6,4.3-1.9,6.6c0,0,0,0,0,0.1c-0.1,0.8-0.2,1.6-0.2,2.4c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1v2 c0,0,0,0.1,0,0.1c0.2,2.6,1,5.1,2.2,7.4c0.4,0.6,1.1,1,1.8,1h16c0.8,0,1.6-0.5,1.9-1.2C37.6,27.7,34.4,21.3,32.9,19.1z M23.1,25.3 c0.1,0.6-0.4,1.2-1.1,1.3h-7.7c-0.5,0-1-0.4-1.1-1c0-0.2,0-0.4,0-0.6c0.2-1.2,0.5-2.4,0.9-3.6c0.3-1.1,1.3-1.9,2.5-1.9H22 c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6l0.1,2.6L23.1,25.3z M33,26.5c-0.1,0-0.1,0-0.2,0v0H25 c-0.6-0.1-1.1-0.6-1.1-1.2v-4.6c0-0.1,0-0.2,0-0.3c0-0.3,0.2-0.5,0.3-0.7l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2h0.2c0,0,0.1,0,0.1,0 l5.3,0.1c1.2,0.1,2.2,0.8,2.5,1.9c0.4,1.2,0.7,2.4,0.9,3.6C34,25.7,33.6,26.3,33,26.5z"
      />
      <linearGradient
        id="SVGID_1"
        gradientUnits="userSpaceOnUse"
        x1={11.4332}
        y1={21.6349}
        x2={35.4856}
        y2={21.6349}
      >
        <stop
          offset={0.3328}
          style={{
            stopColor: '#DAA76E',
          }}
        />
        <stop
          offset={0.7528}
          style={{
            stopColor: '#EDC679',
          }}
        />
      </linearGradient>
      <path
        style={{
          fill: 'url(#SVGID_1)',
        }}
        d="M32.9,19.1c-0.4-0.6-1-0.9-1.7-0.9h-5.9v0 c1.2-3.5,1.2-7.2,0.1-10.6v0l-1.2-2.8c0,0,0,0,0,0c-0.1-0.2-0.2-0.4-0.3-0.6l-3.5,1.5l-6.6,2.9l0.5,1.1l0.5,1.1l0.6,1.2l6.5-2.9 c0.9,3,0.6,6.2-0.6,9.1c0,0,0,0,0,0h-5.7c-0.8,0-1.6,0.5-1.9,1.2c-0.9,2.1-1.6,4.3-1.9,6.6c0,0,0,0,0,0.1c-0.1,0.8-0.2,1.6-0.2,2.4 c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1v2c0,0,0,0.1,0,0.1c0.2,2.6,1,5.1,2.2,7.4c0.4,0.6,1.1,1,1.8,1h16c0.8,0,1.6-0.5,1.9-1.2 C37.6,27.7,34.4,21.3,32.9,19.1z M23.1,25.3c0.1,0.6-0.4,1.2-1.1,1.3h-7.7c-0.5,0-1-0.4-1.1-1c0-0.2,0-0.4,0-0.6 c0.2-1.2,0.5-2.4,0.9-3.6c0.3-1.1,1.3-1.9,2.5-1.9H22c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6 l0.1,2.6L23.1,25.3z M33,26.5c-0.1,0-0.1,0-0.2,0v0H25c-0.6-0.1-1.1-0.6-1.1-1.2v-4.6c0-0.1,0-0.2,0-0.3c0-0.3,0.2-0.5,0.3-0.7 l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2h0.2c0,0,0.1,0,0.1,0l5.3,0.1c1.2,0.1,2.2,0.8,2.5,1.9c0.4,1.2,0.7,2.4,0.9,3.6 C34,25.6,33.6,26.3,33,26.5z"
      />
    </g>
  </svg>
);
